<template>
<div id="contact" class="container-fluid">
            <div class="contact-overlay">

            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                                
                        <div class="headline">

                            <h1  class="preview-content">{{section}}</h1>
                                    
                        </div><!-- end .headline -->
                                
                    </div><!-- end .span12 -->
                </div><!-- end .row -->
                        
                <div class="row">
                    <div class="col-md-4">
   
                        <h2 class="text-uppercase">{{address}}</h2>
                                
                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-home"></i>
                                Via Domenico Cimarosa n.2, Monza (MB) - 20900 ITA
                            </li>
                            <li>
                                <i class="fa fa-phone"></i>
                                (+39) 3883636249
                            </li>
                            <li>
                                <i class="fa fa-envelope-o"></i>
                                <a href="mailto:info@andresantacroce.com">info@andresantacroce.com</a>
                            </li>
                            <li>
                                <i class="fa fa-skype"></i>
                                info.andresantacroce
                            </li>
                        </ul>
                                
                        <h2 class="text-uppercase">{{social}}</h2>
                                
                        <div class="social-media">
                            <a href="http://www.facebook.com/thedoms" class="facebook-icon social-icon text-center" target="_blank">
                                <i class="fa fa-facebook"></i>
                            </a>
                                    
                            <a href="https://plus.google.com/u/0/112453921197928637108/posts" class="googleplus-icon social-icon text-center" target="_blank">
                                <i class="fa fa-google-plus"></i>
                            </a>
                                    
                            <a href="https://www.linkedin.com/in/andresantacroce" class="linkedin-icon social-icon text-center" target="_blank">
                                <i class="fa fa-linkedin"></i>
                            </a>     
                        </div>
                        <div class="clearfix"></div>
                                
                    </div><!-- end .span4 -->
                    <div class="col-md-8">
                        
                        <h2 class="text-uppercase">{{sendmessage}}</h2>
                        
                        <form autocomplete="off" id="contactForm"  name="contactForm" @submit="sendMessage">   
                                                
                            <input ref="name" id="name" v-model="sender.name" type="text" name="name" :placeholder="nameplaceholder">       
                            <p style="color: red;">{{nameerrorprop}}</p> 
                            
                            <input ref="email" id="email" data-ng-pattern="/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/" v-model="sender.email" type="text" name="email" :placeholder="senderplaceholder">
                            <p style="color: red;">{{emailerrorprop}}</p>

                            <input ref="subject" id="subject" v-model="sender.subject" type="text" name="subject" value="" :placeholder="subjectplaceholder">
                            <p style="color: red;">{{subjecterrorprop}}</p>                               
                            
                            <textarea ref="message" id="message" v-model="sender.message"  name="message" rows="4" cols="25" :placeholder="messageplaceholder"></textarea>
                            <p style="color: red;">{{messageerrorprop}}</p>           
                            
                            <button style="float: left" data-ng-class="{'disabled' : contactForm.$invalid}" id="btn-submit" type="submit" name="submit" >{{submitbutton}}</button>

                            <span id="resultMessage" :class="messageResult == 'OK' ? 'success' : 'error'">{{messageDescription}}</span>
                        </form>
    
                    </div><!-- end .span8 -->
                </div><!-- end .row -->
            </div>
            
        </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";


export default {
  name: "Contacts",
  components: {
  },
  data: () => {
    return {
      section: "",
      sectionSub: "",
      address: "",
      social: "",
      sendmessage: "",
      nameplaceholder: "",
      nameerror: "",
      senderplaceholder: "",
      emailerror: "",
      subjectplaceholder: "",
      subjecterror: "",
      submitbutton: "",
      sendcopy: "",
      messageerror: "",
      messageplaceholder: "",
      submitted: false,
      sender: {},
      messageResult: "",
      messageDescription: ""
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"]),
    nameerrorprop: function (){
      return this.validateField(this.sender.name, this.nameerror);
    },
    emailerrorprop: function (){
      return this.validateField(this.sender.email, this.emailerror);
    },
    subjecterrorprop: function (){
      return this.validateField(this.sender.subject, this.subjecterror);
    },
    messageerrorprop: function (){
      return this.validateField(this.sender.message, this.messageerror);
    }
  },
  methods: {
    validateField(field, error){
      if(this.submitted == true)
      {
          if(!field)
          {
              return error;
          }
      }

      return "";
    },
    loadData() {
      let contactSection = this.getSectionByName('contact');
      console.log('contact section');
      console.log(contactSection);

      this.section = contactSection.LocalizedTitle;
      this.sectionSub = contactSection.LocalizedSubTitle;
      this.address = contactSection.SubSections[0];
      this.social = contactSection.SubSections[1];
      this.sendmessage = contactSection.SubSections[2];

      this.nameplaceholder = contactSection.SubSections[3];
      this.nameerror = contactSection.SubSections[4];

      this.senderplaceholder = contactSection.SubSections[5];
      this.emailerror = contactSection.SubSections[6];

      this.subjectplaceholder = contactSection.SubSections[7];
      this.subjecterror = contactSection.SubSections[8];

      this.messageplaceholder = contactSection.SubSections[9];
      this.messageerror = contactSection.SubSections[10];

      this.submitbutton = contactSection.SubSections[11];
      this.sendcopy = contactSection.SubSections[12];
    },
    async sendMessage (e) {
      this.submitted = true;
      e.preventDefault();
      
      if (!this.sender.name) {
        this.$refs.name.focus();
        return false;
      }
      else if(!this.sender.email)
      {
        this.$refs.email.focus();
        return false;
      }
      else if(!this.sender.subject)
      {
        this.$refs.subject.focus();
        return false;
      }
      else if(!this.sender.message)
      {
        this.$refs.message.focus();
        return false;
      }

      var contactRequest = {};
      contactRequest.name = this.sender.name;
      contactRequest.subject = this.sender.subject;
      contactRequest.message = this.sender.message;
      contactRequest.email = this.sender.email;

      const options = {
          method: 'POST',
          body: JSON.stringify(contactRequest),
          headers: {
              'Content-Type': 'application/json'
          }
      }

      let response = await fetch(
        this.$store.state.apiurl + '/contact',
        options
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('message sent');
        console.log(data);

        this.messageResult = data.result;

        //Reset Form
        if(data.result == 'OK')
        {
            console.log('message sent');
            this.sender = {};
            this.submitted = false;

            this.messageDescription = this.language == "en" ? "Thank you! I'll get back as soon as possible." : "Grazie! Ti ricontatterò appena possibile.";
        }
        else
        {
            this.messageDescription = this.language == "en"  ? "Ops... we have an error! Try again later." : "Ops... si è verificato un errore. Riprova più tardi.";
        }
      }
    }
  },
  
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-check{
  font-size: 12px;  
}
</style>
