<template>
  <div id="latest-articles" class="container-fluid">
    <div class="overlay"></div>
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="headline">
          <h1 class="preview-content">{{ section }}</h1>
        </div>
      </div>
    </div>
    <div>
    <div class="container" style="z-index: 1000; position: relative">
    <transition-group v-if="posts.length > 0" name="fade" tag="div">
      <div  v-for="i in [currentIndex]" :key="i">
        <h3 class="text-uppercase post-title" style="color: white">{{currentPost.title}}</h3>
        <p class="post-date">14/02/2021 - #azure #ai #aad</p>
          <div
            class="text-justify"
            id="profile-desc"
            v-html="currentPost.contentMarkdown"
            >
 
          </div>
          <div class="text-center">
          <button @click="redirect(currentPost.slug)" style="margin-top: 20px; background-color: transparent; border: 1px solid white; text-align: center">Read More</button>
          </div>
      </div>
    </transition-group>
    </div>
    <a class="prev" @click="prev" >&#10094; Previous</a>
    <a class="next" @click="next" >Next &#10095; </a>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "LatestPosts",
  components: {},
  props: ["top-margin"],
  data: () => {
    return {
      section: "Latest Posts",
      sectionSub: "Sub",
      posts: [],
      timer: null,
      currentIndex: 0
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"]),
    currentPost: function() {
      return this.posts[Math.abs(this.currentIndex) % this.posts.length];
    }
  },
  mounted: function() {
    this.startSlide();
  },
  methods: {
    async loadData() {
      let personalSection = this.getSectionByName("personal");
      console.log(personalSection);

      let response = await fetch(
        this.$store.state.apiurl + '/posts'
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded posts');
        console.log(data);

        this.posts = data;
      }
    },
    redirect(slug){
      window.open(
        'https://blog.andresantacroce.com/' + slug,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    startSlide: function() {
      this.timer = setInterval(this.next, 20000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#latest-articles {
  background: url("/img/1920x1000.jpg");
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-position: 50% 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  color: white;
  height: 450px;
}
@media screen and (max-width: 992px) {
  #latest-articles {
    height: 600px;
  }
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-attachment: scroll;
  background-repeat: repeat;
  background-position: 0 0;
  background-image: url(/img/pattern.png);
}
.preview-content {
  color: white;
}
.headline {
  color: white;
  border: solid 1px white;
  z-index: 200;
}


.fade-enter-active
{
  transition: all 2.5s ease;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  opacity: 0;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 30%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
  z-index: 1000;
  margin-bottom: 40px;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.9);
}

.post-title
{
  margin-bottom: 0px;
}
.post-date{
  margin-top: 0px;
  font-size: 14px;
}
</style>
