<template>
  <div id="personal-profile" class="container-fluid">
    <div class="container">
      <SectionHeader v-bind:sectionSub="sectionSub" v-bind:section="section" />
      
      <div class="row">
        <div class="col-md-3 text-center">
          <img class="img-responsive" src="/img/profile.jpg" alt="" />

          <a
            class="btn fa fa-download"
            data-ng-click="setDownloadLang()"
            data-toggle="modal"
            data-target="#myModal"
            id="cvBtnIT"
          >
            <span class="download-button">{{ language == 'it' ? 'Scarica il mio CV' : 'Download my CV'}}</span>
          </a>

          <!-- Modal -->
          <div
            class="modal fade"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h3 class="modal-title" id="myModalLabel">
                    {{language == 'it' ? 'My CV': 'Il mio CV'}}
                  </h3>
                  <!-- <label class="radiolabel" for="itaRdb">{{language == 'it' ? 'Italiano' : 'Italian'}}</label -->
                  >
                  <!-- <input
                    type="radio"
                    id="itaRdb"
                    name="languageGroup"
                    value="it"
                    v-model="cvlanguage"
                  />
                  <label class="radiolabel" for="engRdb"
                    >{{language == 'it' ? 'Inglese' : 'English'}}</label
                  >
                  <input
                    type="radio"
                    id="engRdb"
                    name="languageGroup"
                    value="en"
                    v-model="cvlanguage"
                  /> -->
                </div>
                <div class="modal-body">
                  <!-- TODO review Interpolation -->
                  <a v-bind:href="pdfcvpath">
                    <i class="fa fa-file-pdf-o fa-4x"></i>
                      <span>{{language == 'it' ? '(.pdf - formato file Adobe)' : '(.pdf - Adobe file format)'}}</span>
                  </a>
                  <a v-bind:href="wordcvpath">
                    <i class="fa fa-file-word-o fa-4x"></i>
                      <span>{{language == 'it' ? '(.docx - formato file Word)' : '(.docx - Word file format)'}}</span>
                  </a>
                </div>
                <div class="modal-footer">
                  {{language == 'it' ? 'seleziona il formato file' : 'choose desired file format'}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end .span3 -->
        <div class="col-md-4">
          <ul class="personal-details">
            <li>
              <label>{{ name }}</label
              >: Andrè Dominic Santacroce
            </li>
            <li>
              <label>{{ birth }}</label
              >: 17 April 1983
            </li>
            <li>
              <label>{{ nationalityLabel }}</label
              >: {{ nationality }}
            </li>
            <li>
              <label>{{ address }}</label
              >: Via Domenico Cimarosa n.2, Monza - 20900, IT
            </li>
            <li>
              <label>{{ phone }}</label
              >: <span>(+39)</span> <span>3883636249</span>
            </li>
            <li>
              <label>{{ email }}</label
              >:
              <a href="mailto:info@andresantacroce.com"
                >info@andresantacroce.com</a
              >
            </li>
          </ul>
        </div>
        <!-- end .span4 -->
        <div class="col-md-5">
          <h3 class="text-uppercase">{{profileLabel}}</h3>

          <div
            class="text-justify"
            id="profile-desc"
            v-html="profileDescription"
          ></div>
        </div>
        <!-- end .span5 -->
      </div>
      <!-- end .row -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SectionHeader from './SectionHeader.vue'

export default {
  name: "PersonalProfile",
  components: {
    SectionHeader
  },
  props: [
    "top-margin"
  ],
  data: () => {
    return {
      section: "",
      sectionSub: "",
      name: "",
      surname: "",
      birth: "",
      nationalityLabel: "",
      nationality: "",
      address: "",
      phone: "",
      email: "",
      download: "",
      cvlanguage: "en",
      cvformat: "pdf",
      profileLabel: "",
      profileDescription : ""
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"]),
    wordcvpath: function () {
      return "https://andresantacrocestorage.blob.core.windows.net/mycv/2021_CV_SantacroceAndre_EN_Europass.docx";
    },
    pdfcvpath: function () {
      return "https://andresantacrocestorage.blob.core.windows.net/mycv/2021_CV_SantacroceAndre_EN_Europass.pdf";
    }
  },
  methods: {
    async loadData() {
      let personalSection = this.getSectionByName('personal');
      console.log(personalSection);

      this.section = personalSection.LocalizedTitle;
      this.sectionSub = personalSection.LocalizedSubTitle;
      this.name = personalSection.SubSections[0];
      this.birth = personalSection.SubSections[1];
      this.nationalityLabel = personalSection.SubSections[2];
      this.nationality = personalSection.SubSections[3];
      this.address = personalSection.SubSections[4];
      this.phone = personalSection.SubSections[5];
      this.email = personalSection.SubSections[6];
      this.profileLabel = personalSection.SubSections[7];

      let response = await fetch(
        this.$store.state.apiurl + '/profile/' + this.language
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded profile');
        console.log(data);

        this.profileDescription = data.description;
      }

    },
  },
  
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.radiolabel {
  margin-right: 6px;
}
.download-button{
  margin-left: 4px;
}
</style>
