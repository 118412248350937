<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="headline">
        <p>
          <span>{{ sectionSub }}</span>
        </p>
        <h1>{{ section }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: ['section', 'sectionSub'],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
