<template>
<div id="education" class="container-fluid">
            <div class="container">
                <SectionHeader v-bind:sectionSub="sectionSub" v-bind:section="section" />
                
                <div class="row cv-section" v-for="(ed, index) in education" :key="index">
                    <div class="col-md-3">
                        <SectionHighlights :exp="ed" :language="language" />
                    </div>
                    <div class="col-md-9">
                        <div class="cv-item">
                            <h3 class="text-uppercase">{{educationLabel}}</h3>

                            <div v-html="ed.Description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SectionHeader from './SectionHeader.vue'
import SectionHighlights from './SectionHighlights.vue'

export default {
  name: "Education",
  components: {
    SectionHeader,
    SectionHighlights
  },
  data: () => {
    return {
      section: "",
      sectionSub: "",
      education: null,
      educationLabel: null
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"])
  },
  methods: {
    async loadData() {
      let educationSection = this.getSectionByName('education');
      console.log(educationSection);

      this.section = educationSection.LocalizedTitle;
      this.sectionSub = educationSection.LocalizedSubTitle;
      this.educationLabel = educationSection.SubSections[0];


      let response = await fetch(
        this.$store.state.apiurl + '/education/' + this.language
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded education');
        console.log(data);

        this.education = data.Education;
      }
    },
  },
  
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
