<template>
  <div class="cv-section-title">
    <h1>
      <span>
        <!-- we need to manage two different models (work/educaiton), we should then unify -->
        {{ exp.StartYear == null ? exp.FromYear : exp.StartYear }}
        <small>{{ exp.StartMonth }}</small>
      </span>
      -
      <span v-if="exp.IsCurrent">{{
        language == "it" ? "Presente" : "Present"
      }}</span>

      <span v-else>
        {{ exp.EndYear == null ? exp.ToYear : exp.EndYear }}
        <small>{{ exp.EndMonth }}</small>
      </span>
    </h1>

    <h3>{{ exp.Title }}</h3>

    <p v-for="(ac, index) in exp.Activities" :key="index">
      {{ ac.Title }}<br />
    </p>

    <p v-if="exp.SubTitle != null">{{exp.SubTitle}}</p>

    <p v-if="exp.Level != null">{{exp.Level}}</p>
  </div>
</template>

<script>

export default {
  name: "SectionHighlights",
  props: ['exp', 'language']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
