<template>
<div id="work-experience" class="container-fluid">
            <div class="container">
                <SectionHeader v-bind:sectionSub="sectionSub" v-bind:section="section" />
                
                <div class="row cv-section" v-for="(exp, index) in experiences" :key="index">
                    <div class="col-md-3">
                        <SectionHighlights :exp="exp" :language="language" />
                    </div>
                    <div class="col-md-9">
                        <div class="cv-item">
                            <h3 class="text-uppercase">{{language == 'it' ? 'Descrizione Attività' : 'Job Description'}}
                            </h3>

                            <p v-for="(act, index) in exp.Activities" :key="index">
                                
                                {{act.Description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SectionHeader from './SectionHeader.vue'
import SectionHighlights from './SectionHighlights.vue'

export default {
  name: "WorkExperience",
  components: {
    SectionHeader,
    SectionHighlights
  },
  data: () => {
    return {
      section: "",
      sectionSub: "",
      experiences: null
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"])
  },
  methods: {
    async loadData() {
      let experienceSection = this.getSectionByName('experience');
      console.log(experienceSection);

      this.section = experienceSection.LocalizedTitle;
      this.sectionSub = experienceSection.LocalizedSubTitle;

      let response = await fetch(
        this.$store.state.apiurl + '/experience/' + this.language
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded experiences');
        console.log(data);

        this.experiences = data.Experience;
      }

    },
  },
  
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
