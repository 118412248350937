<template>
 <div :class="'col-md-' + size">
  <h2 class="text-uppercase">{{title}}</h2>

  
  <ul class="check" v-for="(sk, index) in skills" :key="index">
      <li><i class="fa fa-check fa-1"></i>
<span class="glyphicon"></span>{{sk}}</li>
  </ul>
 </div>
</template>

<script>

export default {
  name: "SkillSection",
  props: ['title', 'skills', 'size']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-check{
  font-size: 12px;  
}
</style>
