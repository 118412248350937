import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: null,
    sections: { },
    apiurl: 'https://api.andresantacroce.com/cv'
  },
  getters: {
    getSectionByName: (state) => (sectionName) => {
      return state.sections[sectionName];
    }
  },
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
      console.log('language updated to: ' + lang);
    },
    saveSectionData(state, data){
      state.sections = data;
    }
  },
  actions: {
    async changeLanguage({commit}, payload) {
      console.log("calling api");
      console.log(payload);

      let response = await fetch(
        this.state.apiurl + '/sections/' + payload.lang
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded section data from Vuex');
        console.log(data);
        
        console.log(payload.lang);
        commit('setLanguage', payload.lang);
        commit('saveSectionData', data.Sections);
      }
    },
  },
  modules: {
  }
})
