<template>
  <footer class="footer">
        <div class="container">
            <p class="text-center smaller">
              Copyright &copy; 2021 Andrè Dominic Santacroce - P.IVA 11361980961
              
              <span style="font-size: 10px"></span><br>
              <span class="version">v2.0.3</span>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.smaller
{
  font-size: 14px;
}
.version {
font-size: 12px;
margin: 0px;
padding: 0px;
position: absolute;
top: 60px;
}
</style>
