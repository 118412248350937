<template>
<div id="skills" class="container-fluid">
            <div class="container">
                <SectionHeader v-bind:sectionSub="sectionSub" v-bind:section="section" />
                
                <div class="row">
                    <!-- Programming Languages -->
                    <div class="col-md-7">

                        <h2 class="text-uppercase">{{languagesLabel}}</h2>

                        <div class="fixed" v-for="(pl, index) in skills" :key="index">

                            <div class="progress-bar-description">
                                {{pl.Name}}
                                <span :style="'left:' + pl.Percent">{{pl.Percent}}</span>
                            </div>

                            <div class="progress-bar">
                                <span class="progress-bar-outer" :data-width="pl.Percent">
                                    <span class="progress-bar-inner"></span>
                                </span>
                            </div>

                        </div>
                    </div>

                    <!-- Certifications -->
                    <div class="col-md-5">

                        <h2 class="text-uppercase">{{certificationsLabel}}</h2>

                        <div class="icon-box-1" v-for="(cert, index) in certifications" :key="index">
                            <i>{{cert.code}}</i>

                            <div class="icon-box-content">

                                <h3>{{cert.title}}</h3>

                                <div v-html="cert.description"></div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Frameworks -->
                <div class="row">
                    <SkillSection :title="frameworksTitle" :skills="skills1" :size="4" />

                    <SkillSection :title="protocolsTitle" :skills="skills2" :size="3" />

                    <SkillSection :title="platformsTitle" :skills="skills3" :size="5" />
                </div>
            </div>
        </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SectionHeader from './SectionHeader.vue'
import SkillSection from './SkillSection.vue'

export default {
  name: "Skills",
  components: {
    SectionHeader,
    SkillSection
  },
  data: () => {
    return {
      section: "",
      sectionSub: "",
      languagesLabel: null,
      certificationsLabel: null,
      certifications: [],
      frameworksTitle: "",
      platformsTitle: "",
      protocolsTitle: "",
      skills: null,
      skills1: [],
      skills2: [],
      skills3: [],
    };
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"])
  },
  methods: {
    async loadData() {
      let skillSection = this.getSectionByName('skills');
      console.log(skillSection);

      this.section = skillSection.LocalizedTitle;
      this.sectionSub = skillSection.LocalizedSubTitle;
      this.languagesLabel = skillSection.SubSections[0];
      this.certificationsLabel = skillSection.SubSections[1];
      this.frameworksTitle = skillSection.SubSections[2];
      this.protocolsTitle = skillSection.SubSections[3];
      this.platformsTitle = skillSection.SubSections[4];

      let response = await fetch(
        this.$store.state.apiurl + '/skills'
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded skills');
        console.log(data);

        this.skills = data.Skills.ProgrammingLanguages;
        this.skills1 = data.Skills.Other[0].Skills;
        this.skills2 = data.Skills.Other[1].Skills;
        this.skills3 = data.Skills.Other[2].Skills;
      }

      response = await fetch(
        this.$store.state.apiurl + '/certifications'
      );

      if (response.status === 200) {
        let data = await response.json();
        console.log('loaded certifications');
        console.log(data);

        this.certifications = data.certifications;
      }
    },
  },
  
  watch: {
    sections() {
      this.loadData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-check{
  font-size: 12px;  
}
</style>
