<template>
  <header>
    <!-- FullScreen Image -->
    <div id="preview" >
      <div
        class="parallax-overlay"
        style="background-image: url(/img/pattern.png)"
      ></div>

      <div class="container-fluid debug">
        <div
          id="lang"
          class="col-xs-12 text-center preview-content"
          style="font-size: 40px"
        >
          <a
            v-on:click="changeLanguage('it')"
            v-bind:class = "(language == 'it') ? 'selectedLang':''"
            >ITA</a
          >
          <a
            v-on:click="changeLanguage('en')"
            v-bind:class = "(language == 'en') ? 'selectedLang':''"
            >ENG</a
          >
        </div>
      </div>

      <div class="container-fluid">
        <div id="name-desc" class="col-xs-12 text-center preview-content">

          <div>
            <h1>Andrè Dominic</h1>
            <p>
              <span>Senior Cloud Architect</span>
            </p>

          </div>

        </div>
      </div>

      <div class="container-fluid">
        <div id="visit" class="col-xs-12 text-center preview-content">
          <a
            href="#personal-profile"
            class="fa fa-chevron-down up-down-navigation"
          ></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  methods: {
    changeLanguage(lang) {
      this.$store.dispatch("changeLanguage", {"lang" : lang});
    },
  },
  created(){
    this.$store.dispatch('changeLanguage', {"lang" : "en"});
  },
  computed: {
    ...mapState(["language"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
