<template>
  <div id="app">
    <div id="pageloader">
      <div class="loader-img">
        <img src="/img/loader.gif" alt="" />
      </div>
      <!-- end .loader-img -->
    </div>
    <!-- end .pageloader -->
    <Header />

    <a
      id="back-to-top"
      class="fa fa-chevron-up up-down-navigation text-center"
      href="#preview"
    ></a>

    <Navigation />

    <div id="main-container" class="container-fluid">
      <PersonalProfile />

      <LatestPosts />
      
      <WorkExperience />

      <Education />

      <Skills />
    </div>

    <Contacts />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import PersonalProfile from "./components/PersonalProfile.vue";
import LatestPosts from "./components/LatestPosts.vue";
import WorkExperience from "./components/WorkExperience.vue";
import Education from "./components/Education.vue";
import Skills from "./components/Skills.vue";
import Contacts from "./components/Contacts.vue";
import Footer from "./components/Footer.vue";
// import Carousel from "./components/Carousel.vue";

export default {
  name: "App",
  components: {
    Header,
    Navigation,
    PersonalProfile,
    LatestPosts,
    WorkExperience,
    Education,
    Skills,
    Contacts,
    Footer,
    // Carousel
  },
};
</script>

<style>
</style>
