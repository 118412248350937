<template>
  <nav class="navbar navbar-default navbar-fixed-top" style="display: none;" role="navigation">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
                    aria-expanded="false" aria-controls="navbar">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" href="#preview">Andre Dominic</a>
            </div>

            <div id="navbar" class="collapse navbar-collapse">
                <ul class="nav navbar-nav">
                    <li class="active"><a href="#personal-profile">{{personalSection}}</a></li>
                    <li><a href="#latest-articles">BLOG</a></li>
                    <li><a href="#work-experience">{{experienceSection}}</a></li>
                    <li><a href="#education">{{educationSection}}</a></li>
                    <li><a href="#skills">{{skillsSection}}</a></li>
                    <li><a href="#contact">{{contactSection}}</a></li>
                </ul>
            </div>
            <!--/.nav-collapse -->
        </div>
    </nav>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: 'Navigation',
  data: () => {
    return {
      personalSection: "",
      experienceSection: "",
      educationSection: "",
      skillsSection: "",
      contactSection: ""
    }
    },
  methods: {
    loadData() {
      this.personalSection = this.getSectionByName('personal').LocalizedMenuName;
      this.experienceSection = this.getSectionByName('experience').LocalizedMenuName;
      this.educationSection = this.getSectionByName('education').LocalizedMenuName;
      this.skillsSection = this.getSectionByName('skills').LocalizedMenuName;
      this.contactSection = this.getSectionByName('contact').LocalizedMenuName;
    },
  },
  watch: {
    sections() {
      this.loadData();
    },
  },
  computed: {
    ...mapState(["sections", "language"]),
    ...mapGetters(["getSectionByName"])
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
